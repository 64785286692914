import * as _ from "lodash";
export default class ScrollHelper {
    private refs: { [id: string]: HTMLElement | null } = {};
    public scrollMode: any = { behavior: 'smooth', block: 'start' };
    public keptScrollTo?: string;

    public getRef = (id: string) => {
        return this.setRef(id);
    }

    private reallyScroll = (delay: number) => _.debounce((ele: HTMLElement, mode: any, delay?: number) => {
        ele.scrollIntoView(mode);
    }, delay);

    public scrollTo = (id: string, delay?: number) => {
        //        console.log("trying to scroll to => ", id);
        //        console.log("sr => ", this.refs, ", h => ", this.refs[id]?.offsetTop);
        if (this.refs[id]) {
            //            console.log("scrolling!");
            const ele = this.refs[id];
            if (ele) {
                //                console.log(`scrolling! to ${id} => ${ele.offsetTop}`);
                this.reallyScroll(delay ?? 250)(ele, this.scrollMode);
            } else
                this.keptScrollTo = id;
        }
        else
            this.keptScrollTo = id;
    }

    public addRef = (id: string, data: HTMLElement | null) => {
        //        console.log("id => ", id, ", => ", data);
        this.refs[id] = data;

    }

    public resetRefs = (keep?: string | string[]) => {
        const toKeep: Array<{ id: string, data: HTMLElement | null }> = [];
        const _keep = keep ? (_.isArray(keep) ? keep : [keep]) : [];
        _.forEach(_keep, k => {
            if (this.refs[k])
                toKeep.push({ id: k, data: this.refs[k] });
        });
        this.refs = {};
        _.forEach(toKeep, v => this.refs[v.id] = v.data);
    }

    public setRef = (name: string) => (ref: HTMLElement | null) => {
        this.addRef(name, ref);
        if (ref)
            this.keptScroll(name);
    }

    private onScrollsToClean: any[] = [];
    public cleanUp = () => {
        _.forEach(this.onScrollsToClean, o => o.onscroll = () => {/**/ });
        this.onScrollsToClean = [];
    }

    public setOnScrollOnParent = (callback: (ev: Event) => Promise<void>, debug?: boolean) => (h: HTMLElement | null) => {
        let parent = h;
        while (parent && parent !== null) {
            if (debug)
                console.log('ScrollHelper.tsx:62:parent => ', parent);
            if (parent.className.indexOf("contentScroll") >= 0) {
                if (debug)
                    console.log('ScrollHelper.tsx:65:found parent!');
                parent.addEventListener("onscroll", callback, { passive: true });
                parent.onscroll = callback;
                this.onScrollsToClean.push(parent);
                break;
            }
            parent = parent.parentElement;
        }
    }



    public executeOnScroll = (threshold: number, callback: () => Promise<void> | void, condition: () => boolean, debug?: boolean) => {
        let working = false;
        return async (event: Event) => {
            if (debug)
                console.log('ScrollHelper.tsx:81:scroll-event');
            const t = event.currentTarget as HTMLElement;
            if (debug)
                console.log(`ScrollHelper.tsx:84:(sh, st) => (${t?.scrollHeight}, ${t?.scrollTop})`);
            if (t && t.scrollHeight - t.scrollTop - t.clientHeight < threshold) {
                if (condition() && !working) {
                    working = true;
                    const cl = _.debounce(callback, 500);
                    await cl();
                    //await callback();
                    working = false;
                }
            }
        }
    }

    private keptScroll = (name: string) => {
        if (this.keptScrollTo === name) {
            //            console.log("keptScroll => ", this.keptScrollTo);
            this.scrollTo(this.keptScrollTo);
            this.keptScrollTo = undefined;
        }
    }
}
