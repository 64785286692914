import { BestPracticePostDownloadDto, ControllerHelper, PostController, PostDownloadDto } from 'collaboration-service';
import ContentPost from 'components/Posts/ContentPost/ContentPost';
import PorscheMomentPostMobile from 'components/Posts/PorscheMomentPost/PorscheMomentPostMobile';
import PorscheMomentPostMobileLoader from 'components/Posts/PorscheMomentPost/PorscheMomentPostMobileLoader';
import QuizPost from 'components/Posts/QuizPost/QuizPost';
import SlideshowPost from 'components/Posts/SlideshowPost/SlideshowPost';
import StandardPost from 'components/Posts/StandardPost';
import TubePost from 'components/Posts/TubePost/TubePost';
import { fromThemeCreator, styled, useThemePart } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { Actions, WorkingActions } from 'services/ApplicationState/Actions';
import { PluginActions } from 'services/ApplicationState/PluginStateHandling';
import { shallowCompare } from 'services/Helpers';
import ScrollHelper from 'services/Helpers/ScrollHelper';
import { useImgI18N } from 'services/ImgI18N';
import { changeLastGroup } from 'services/StoreDependantHelpers';
import PostHubConnection, { PostHubEventType } from 'services/signalR/PostHubConnection';
import useOnScreenDiv from 'services/useOnScreenDiv';
import BestPracticePost from 'views/BestPractice/BestPracticePost';
import NewsPost from 'views/News/NewsPost';
import { ApplicationState, useAppDispatch, useAppSelector } from '../../services/ApplicationState/ApplicationState';
import ScrollKeeper from '../../services/Helpers/ScrollKeeper';
import history from '../../services/History';
import { createFetchPostFilter } from './FeedFilter';
import { FeedTheme } from './FeedMain';
import { FeedSC as T } from './FeedSC';

const mapper = (state: ApplicationState) => ({
    trending: state.feedState.trending,
    userGroups: state.userGroups,
    user: state.user,
    posts: state.posts,
    token: state.postsToken,
    currentGroup: state.currentGroup,
    feedSearchProps: state.feedState.searchProps,
});
export interface FeedProps {
}
const selectFromTheme = fromThemeCreator<FeedTheme>("feedTheme");

const PostContainerDivOuter = styled.div`
    margin: auto;
    width: 100%;
    padding: 15px 0;
    position: relative;
    margin-bottom: -1px;
`;
const PostContainerDivOuterBg = styled.div`
    position: absolute;
    inset: 1px;
    background: linear-gradient(90deg, #fff 10%, rgb(240, 239, 247), #fff 90%);
`;

const PostContainerDiv = styled.div`
    margin: auto;
    width: 100%;
    max-width: ${props => selectFromTheme(props, t => t.post.maxWidth, t => "640px")};
    min-height: 100px;
    position: relative;
`;

export const RenderPost = (p: { post: PostDownloadDto, postChanged: (post: PostDownloadDto) => void }) => {
    const { post, postChanged } = p;
    const renderedPost = React.useMemo(() => {
        const type = post?.type;
        switch (type) {
            case "ContentPost":
                return <ContentPost post={post} postChanged={postChanged} />;
            case "TubePost":
                return <TubePost post={post} postChanged={postChanged} />;
            case "CommunityFeedPost":
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} />;
            case "PorscheMomentPost":
                return <PorscheMomentPostMobile post={post} postChanged={postChanged} />;
            case "SlideShow":
                return <SlideshowPost post={post} postChanged={postChanged} />;
            case "Quiz":
                return <QuizPost post={post} postChanged={postChanged} />;
            case "NewsPost":
                return <NewsPost post={post} postChanged={postChanged} />;
            case "BestPracticePost":
                return <BestPracticePost post={post as BestPracticePostDownloadDto} />;
            default:
                return <StandardPost post={post} postChanged={postChanged} />
        }

    }, [post, postChanged]);
    return (
        <PostContainerDivOuter key={post.id} className="PostContainerDivOuter">
            <PostContainerDivOuterBg />
            <PostContainerDiv>
                {renderedPost}
            </PostContainerDiv>
        </PostContainerDivOuter>
    );
}

const Feed = (p: FeedProps) => {

    //const { } = p;
    const { user, userGroups, currentGroup, token, trending, feedSearchProps, posts } = useAppSelector(mapper, shallowCompare);
    const dispatch = useAppDispatch();
    const { t } = useImgI18N("feed");
    const [scrollHelper,] = React.useState<ScrollHelper>(new ScrollHelper());
    const [scroll,] = React.useState<ScrollKeeper>(ScrollKeeper.get(history.location));
    const browser = useThemePart(x => x.browser);
    console.log("Feed.tsx::110 => browser", browser);


    // React.useEffect(() => console.log("Feed.tsx::66 **Changed** => scrollHelper", scrollHelper), [scrollHelper]);
    // React.useEffect(() => console.log("Feed.tsx::67 **Changed** => scroll", scroll), [scroll]);
    // React.useEffect(() => console.log("Feed.tsx::68 **Changed** => token"), [token]);
    // React.useEffect(() => console.log("Feed.tsx::69 **Changed** => user", user), [user]);
    // React.useEffect(() => console.log("Feed.tsx::70 **Changed** => userGroups", userGroups), [userGroups]);
    // React.useEffect(() => console.log("Feed.tsx::71 **Changed** => currentGroup", currentGroup), [currentGroup]);
    // React.useEffect(() => console.log("Feed.tsx::72 **Changed** => trending", trending), [trending]);
    // React.useEffect(() => console.log("Feed.tsx::73 **Changed** => feedSearchProps", JSON.stringify(feedSearchProps)), [feedSearchProps]);
    // React.useEffect(() => console.log("Feed.tsx::74 **Changed** => posts", posts), [posts]);
    // React.useEffect(() => console.log("Feed.tsx::76 **Changed** => dispatch", dispatch), [dispatch]);

    const loadMore = React.useMemo(() => () => {
        console.log("loading more...");
        dispatch(WorkingActions.FEED.loadPosts(false))
    }, [dispatch]);
    const delay = browser === "ios" ? 3000 : 1;
    // const delay = 5000;
    const OnScreenDiv = useOnScreenDiv({ work: loadMore, delay });

    const scrollTo = React.useMemo(() => (id: string) => {
        scrollHelper.scrollTo(id);
    }, [scrollHelper]);

    React.useEffect(() => {
        dispatch(PluginActions.batchActions([
            Actions.setScrollTo(scrollTo),
            PluginActions.setPosts(undefined, undefined)
        ]));

        const onUpdate = async (data: any, type?: PostHubEventType) => {
            const p = data as PostDownloadDto;
            if (p.id && type === "update") {
                const newPost = await ControllerHelper.singleCall({ id: p.id }, PostController.GetSinglePost)
                dispatch(Actions.updatePost(newPost));
            };
        };
        // MUST BE CORRECTED TO FILTERED GROUPS!!!
        const groups = _.map(_.filter(userGroups, ug => ug.groupType === "COMMUNITY" || ug.groupType === "EXCITE"), g => g.id);
        if (currentGroup?.groupType !== "COMMUNITY" && currentGroup?.groupType !== "EXCITE") {
            const eg = _.find(userGroups, g => g.groupType === "EXCITE");
            const cg = _.find(userGroups, g => g.groupType === "COMMUNITY");
            if (eg)
                changeLastGroup(eg);
            else if (cg)
                changeLastGroup(cg);
        }
        const phr = PostHubConnection.getInstance().subscribe(onUpdate);
        phr.signForIds(groups);
        return () => {
            if (scroll)
                scroll.savePosition();
            if (phr)
                phr.unsubscribe();
        }
    }, [dispatch, userGroups, currentGroup, scroll, scrollHelper, scrollTo]);

    React.useEffect(() => {
        const l = async () => {
            const filter = createFetchPostFilter(user, undefined, undefined, undefined, undefined, undefined);
            const res = await ControllerHelper.singleCall({ text: "", filter }, PostController.SearchPostText3); //
            dispatch(Actions.setFeedFacets(res.facets));
        };
        l();
    }, [user, dispatch]);

    React.useEffect(() => {
        dispatch(WorkingActions.FEED.loadPosts(true, undefined));
    }, [dispatch, user, trending, feedSearchProps]);

    const updatePost = React.useMemo(() => (post: PostDownloadDto) => {
        dispatch(Actions.updatePost(post));
    }, [dispatch]);

    return (
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
            <div ref={scrollHelper.getRef("top")} />
            {posts?.length === 0 ?
                <T.NoResults>
                    {t("no posts found")}
                </T.NoResults>
                :
                _.map(posts, (p, i) => {
                    return <RenderPost key={p.id} post={p} postChanged={updatePost} />
                })}
            {token &&
                <PostContainerDivOuter className="PostContainerDivOuter" style={{ paddingBottom: 200 }}>
                    <PostContainerDivOuterBg />
                    <PostContainerDiv>
                        {OnScreenDiv}
                        <PorscheMomentPostMobileLoader />
                    </PostContainerDiv>
                </PostContainerDivOuter>
            }
        </div >
    );
}

export default Feed;