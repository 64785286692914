import { FetchPostsFilter, PostDownloadDto, UserDownloadDto, UserShortInfoDownloadDto } from 'collaboration-service';
import { RadioButton } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'services/ApplicationState/ApplicationState';
import { ComponentWithMappedApplicationState } from 'services/ApplicationState/HelperInterfaces';
import { getFrontEndSettingFromState, setFrontEndSetting } from 'services/Helpers';
import { ImgI18NTranslatedComponentProps, translate } from 'services/ImgI18N';
import { isInGroup } from 'services/StoreDependantHelpers';
import { feedTheme } from './FeedMain';
import { FeedSC as T } from './FeedSC';
import { Actions } from 'services/ApplicationState/Actions';

export const createFetchPostFilter = (
    currentUser: UserDownloadDto | undefined,
    excludedTypes: string[] | undefined,
    ignoreGroups: string[] | undefined,
    exciteStandards: string[] | undefined,
    quizType: string | undefined,
    user: UserShortInfoDownloadDto | undefined,
    includedPostTypes?: string[] | undefined,
    org?: string | null,
    org1?: string | null,
    org2?: string | null,
    isLuxury?: boolean | undefined,
): FetchPostsFilter => {
    const f = excludedTypes ?? getFrontEndSettingFromState<string[]>(currentUser, "searchStickyFilter") ?? [];
    const ignoredGroups = ignoreGroups ?? getFrontEndSettingFromState<string[]>(currentUser, "searchStickyGroupFilter") ?? [];
    const qt = quizType ? quizType : "all";
    const xlude = ["PersonaPost", "ProjectGroup", "ProjectStructureTemplate", "Quiz", "SlideShow", "NewsPost", "BestPracticePost", "TubePost", "PodcastPost", "ContentPost", "BriefingPost", "Asset3D", "WikiArticle", "Playlist", "ScormCourse", "FileEntry", "MilieuPost"];

    const fi: FetchPostsFilter = {
        includedPostTypes,
        excludedPostTypes: includedPostTypes ? undefined : f ? _.uniq([...xlude, ...f]) : xlude,
        ignoredGroups,
        //onlyInGroups: ["3fb40743-4f3b-4858-8f05-141473556c22"],
        onlyInGroups: [],
        standards: exciteStandards,
        onlyQuizzesWithUnfinishedGames: qt === "unfinished",
        onlyUnplayedQuizzes: qt === "unplayed",
        linkedUserId: user?.id ?? "",
        organization: org === null ? undefined : org,
        organization1: org1 === null ? undefined : org1,
        organization2: org2 === null ? undefined : org2,
        isLuxury: isLuxury,
    };
    return fi;
};

export interface FeedSearchProps {
    filter?: FetchPostsFilter,
    token?: string,
    user?: UserShortInfoDownloadDto,
    search?: string,
    posts?: PostDownloadDto[],
    org?: string;
    org1?: string;
    org2?: string;
    isLuxury?: boolean;
};

const mapper = (state: ApplicationState) => ({ user: state.user, feedState: state.feedState });

export interface FeedFilterProps extends ComponentWithMappedApplicationState<typeof mapper>, ImgI18NTranslatedComponentProps {
}

export interface FeedFilterState {
    toggleSearch: boolean;
    search?: string;
    suggestions: string[];
    user?: UserShortInfoDownloadDto;
}

class FeedFilter extends React.Component<FeedFilterProps, FeedFilterState> {
    constructor(props: FeedFilterProps) {
        super(props);

        this.state = {
            toggleSearch: true,
            suggestions: [],
        }
    }

    public render() {
        const excluded = getFrontEndSettingFromState<string[]>(this.props.user, "searchStickyFilter") ?? [];   // this is "exclude" filter
        return (
            <T.Container>
                <T.RadioButtonsContainer >
                    {isInGroup("EXCITE") &&
                        <>
                            <RadioButton
                                onClick={this.turnOn("PorscheMomentPost")}
                                label=""
                                selected={_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0}
                            />
                            <T.RadioButtonDescription
                                onClick={this.turnOn("PorscheMomentPost")}
                                selected={_.findIndex(excluded, f => _.isEqual(f, "PorscheMomentPost")) < 0}
                            >
                                {feedTheme.momentsName}
                            </T.RadioButtonDescription>
                        </>
                    }
                    {isInGroup("COMMUNITY") &&
                        <>
                            <RadioButton
                                onClick={this.turnOn("CommunityFeedPost")}
                                label=""
                                selected={_.findIndex(excluded, f => f === "CommunityFeedPost") < 0}
                            />
                            <T.RadioButtonDescription
                                onClick={this.turnOn("CommunityFeedPost")}
                                selected={_.findIndex(excluded, f => f === "CommunityFeedPost") < 0}
                            >
                                Community
                            </T.RadioButtonDescription>
                        </>
                    }
                    {/*
                     {isInGroup("QUIZ") &&
                        <>
                            <RadioButton
                                onClick={this.turnOn("Quiz")}
                                label=""
                                selected={_.findIndex(excluded, f => _.isEqual(f, "Quiz")) < 0}
                            />
                            <RadioButtonDescription
                                onClick={this.turnOn("Quiz")}
                                selected={_.findIndex(excluded, f => _.isEqual(f, "Quiz")) < 0}
                            >
                                Quiz
                                </RadioButtonDescription>
                        </>
                    }
                    {isInGroup("SLIDESHOW") &&
                        <>
                            <RadioButton
                                onClick={this.turnOn("SlideShow")}
                                label=""
                                selected={_.findIndex(excluded, f => f === "SlideShow") < 0}
                            />
                            <RadioButtonDescription
                                onClick={this.turnOn("SlideShow")}
                                selected={_.findIndex(excluded, f => f === "SlideShow") < 0}
                            >
                                Slides
                                </RadioButtonDescription>
                        </>
                    }
                    <RadioButton
                        onClick={this.turnOn("NewsPost")}
                        label=""
                        selected={_.findIndex(excluded, f => f === "NewsPost") < 0}
                    />
                    <RadioButtonDescription
                        onClick={this.turnOn("NewsPost")}
                        selected={_.findIndex(excluded, f => f === "NewsPost") < 0}
                    >
                        Smart Mobility World
                        </RadioButtonDescription>
                    */}
                </T.RadioButtonsContainer>
            </T.Container>
        )

    }
    private startSearch = async (search?: string, user?: UserShortInfoDownloadDto) => {
        const feedSearchProps: FeedSearchProps = { user, search };
        this.props.dispatch(Actions.setFeedSearchProps(feedSearchProps));
    };


    private turnOn = (group: string) => async () => {
        let excluded = getFrontEndSettingFromState<string[]>(this.props.user, "searchStickyFilter") ?? [];
        const idx = _.findIndex(excluded, f => f === group);
        if (idx < 0)
            excluded.push(group);
        else
            excluded.splice(idx, 1);
        setFrontEndSetting(this.props.user, "searchStickyFilter", changer => excluded);
        this.startSearch(this.state.search, this.state.user);
    };
}
export default translate("feed")(connect(mapper)(FeedFilter));
