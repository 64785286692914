import { ControllerHelper, MediaDownloadDto, PlaylistController, PlaylistDownloadDto, PlaylistItemStateDownloadDto, PlaylistItemStateEnum, PlaylistStateDownloadDto, PlaylistUploadDto } from 'collaboration-service';
import CancelWithConfirmation from 'components/CancelWithConfirmation';
import { formatDistance } from 'date-fns';
import { Icon, Image, ImgIcons, aboveBreakpoint, aboveBreakpointOrEqual } from 'imaginarity-react-ui';
import * as _ from "lodash";
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { convertMinutesToDaysHoursMinutes, countNodeItemsToBeDonePerNode, countPlaylistItemsToBeDone, getHigherDate, getHighestAbsDueDate, getHighestDueDateRelativeInHours, shallowCompare } from 'services/Helpers';
import { createNamedRoute, gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { ImgI18N, useImgI18N } from 'services/ImgI18N';
import { ThemeContext } from 'styled-components';
import { PlaylistSC as T } from './PlaylistSC';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

export interface PlaylistOverviewItemProps {
    data: PlaylistDownloadDto | PlaylistStateDownloadDto;
    isAAO: boolean;
    showBtns: boolean;
}

const PlaylistOverviewItem = (props: PlaylistOverviewItemProps) => {
    const { data, isAAO, showBtns } = props;
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const { t } = useImgI18N("playlist");
    const [playlist, setPlaylist] = React.useState<PlaylistDownloadDto>();
    const [media, setMedia] = React.useState<MediaDownloadDto>();
    const dataType: "Playlist" | "PlaylistStates" = data.hasOwnProperty("playlist") ? "PlaylistStates" : "Playlist";
    const pState = data as PlaylistStateDownloadDto;


    React.useEffect(() => {
        if (dataType === "PlaylistStates") {
            const d = data as PlaylistStateDownloadDto;
            setPlaylist(d.playlist);
            setMedia(d.playlist.media);
        }
        else {
            const d = data as PlaylistDownloadDto;
            setPlaylist(d);
            setMedia(d.media);
        }
    }, [data, dataType])

    const getState = React.useMemo(() => (states: PlaylistItemStateDownloadDto[]): PlaylistItemStateEnum => {
        let ret = 'NotAvailable' as PlaylistItemStateEnum;
        _.forEach(states, st => {
            if (st.state === 'Available')
                ret = 'Available';
        });
        _.forEach(states, st => {
            if (st.state === 'Started')
                ret = 'Started';
        });
        let finisheds = 0;
        _.forEach(states, st => {
            if (st.state === 'Finished')
                finisheds++;
        });
        if (finisheds === states.length)
            ret = 'Finished';
        return ret;
    }, []);

    const thumbnail = _.find(media?.links, l => l.ref === "self")?.uri ?? "";
    const absNodeDueDate = getHighestAbsDueDate(pState);
    const absNodeDueDateRelativeInHours = getHighestDueDateRelativeInHours(pState);
    const dueToDate = getHigherDate(absNodeDueDate, absNodeDueDateRelativeInHours);
    const curNodesDurationSum = (pState.curNodes || []).reduce((acc, curNode) => acc + (curNode.durationInMinutes || 0), 0);
    const prevNodesDurationSum = (pState.prevNodes || []).reduce((acc, prevNode) => acc + (prevNode.durationInMinutes || 0), 0);
    const nextNodesDurationSum = (pState.nextNodes || []).reduce((acc, nextNode) => acc + (nextNode.durationInMinutes || 0), 0);
    const sum = curNodesDurationSum + prevNodesDurationSum + nextNodesDurationSum;
    const sumDurationAll = convertMinutesToDaysHoursMinutes(sum, t, true, false);
    const detailRoute = playlist ? createNamedRoute("playlist_detail", { id: playlist.id }) : undefined;
    const prevNodes = pState.prevNodes ?? [];
    const curNodes = pState.curNodes ?? [];
    const nextNodes = pState.nextNodes ?? [];
    const curNodesReferenceIdsCount = countPlaylistItemsToBeDone(curNodes);
    const prevNodesReferenceIdsCount = countPlaylistItemsToBeDone(prevNodes);
    const nextNodesReferenceIdsCount = countPlaylistItemsToBeDone(nextNodes);
    const allNodesReferenceIdsCount = curNodesReferenceIdsCount + prevNodesReferenceIdsCount + nextNodesReferenceIdsCount;
    const curNode = pState.curNodes[0];
    const theme = React.useContext(ThemeContext);
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const isLaptop = aboveBreakpointOrEqual({ theme }, "tablet");
    const isDesktop = aboveBreakpoint({ theme }, "desktop");


    let criticalCount = 0;
    criticalCount += countNodeItemsToBeDonePerNode(pState, curNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, prevNodes);
    criticalCount += countNodeItemsToBeDonePerNode(pState, nextNodes);

    const getCurrentLearningStatus = React.useMemo(() => (): { icon: ImgIcons; color: string; text: string } => {
        const status = getState(pState.itemStates ?? []);
        const dueTime = new Date(curNode?.absDueDate ?? new Date()).getTime() - new Date().getTime();
        if (status === 'Finished' && pState.nextNodes.length === 0)
            return { icon: 'check circle', color: "@accentGreen", text: "done" };
        if ((status !== 'Finished' && dueTime > 0) || isAAO) {
            return { icon: 'clock', color: "@darkerGrey", text: "on Track" };
        }
        return { icon: 'exclamation triangle', color: "@accentRed", text: "overdue" };
    }, [pState, getState, curNode, isAAO]);

    const delIt = React.useMemo(() => (withEvents: boolean) => async () => {
        if (playlist) {
            await ControllerHelper.singleCall({ playlistId: playlist.id, withEvents }, PlaylistController.ResetPlaylistState);
        }
    }, [playlist]);

    const isAllAtOnce = pState && pState.playlist ? (pState.playlist as PlaylistUploadDto).sequencingMode === "AllAtOnce" : false;
    const isLinear = pState && pState.playlist ? (pState.playlist as PlaylistUploadDto).sequencingMode === "Linear" : false;
    const AAOcontents = pState && (pState.playlist as PlaylistUploadDto).contents;

    const playlistIsFinished = pState.state === "Finished";
    const itemsToBeDone = isAllAtOnce ? (AAOcontents?.length ?? 0) : (allNodesReferenceIdsCount ?? 0);

    const toBeDone = (criticalCount === itemsToBeDone) ? "done" : <>{criticalCount} / {itemsToBeDone}</>;
    const getDateFnsLng = ImgI18N.getInstance().languageLocal();

    const playlistStateIsOptional = pState.optional;


    return (
        <>
            <div />
            <T.PoiLogoContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                {thumbnail.indexOf("notFound") < 0 && <Image src={thumbnail} squared borderColor='#FFF' />}
            </T.PoiLogoContainer>
            <T.PoiDescriptionContainer onClick={detailRoute && playlist ? gotoNamedRouteMethod("playlist_detail", { id: playlist.id }) : undefined}>
                {getTranslated(playlist?.headlines, contentLanguage)?.text}
            </T.PoiDescriptionContainer>
            {isDesktop ?
                <T.PoiAssignContainer>
                    {getTranslatedStandardDate(pState.assignmentDate, "PP")}
                </T.PoiAssignContainer>
                : <div />
            }
            {isTablet ?
                <T.PoiDueToContainer color={((new Date(dueToDate as Date)) >= (new Date()) || playlistStateIsOptional) ? "color" : "@accent"}>
                    {playlistStateIsOptional ? t("optional playlist") : dueToDate && !isNaN(new Date(dueToDate as Date).getTime()) ? (
                        (new Date(dueToDate as Date)) >= (new Date())
                            ? getTranslatedStandardDate(new Date(dueToDate as Date), "PP")
                            : formatDistance(new Date(dueToDate as Date), new Date(), { locale: getDateFnsLng, addSuffix: true })
                    ) : (
                        ""
                    )}
                </T.PoiDueToContainer>
                : <div />
            }
            {isLaptop ?
                <T.PoiDurationContainer>
                    {sum > 0 ? sumDurationAll : ""}
                </T.PoiDurationContainer>
                : <div />
            }
            <T.PoiProgressContainer color={playlistStateIsOptional ? "color" : playlistIsFinished ? "@accentGreen" : getCurrentLearningStatus().color}>
                {/* xxx */}
                <Icon name={playlistStateIsOptional ? "info" : playlistIsFinished ? "check circle" : getCurrentLearningStatus().icon} color={playlistStateIsOptional ? "color" : playlistIsFinished ? "@accentGreen" : getCurrentLearningStatus().color} size={18} marginTop={4} style={{ float: "left", marginRight: 5 }} />
                {(isAllAtOnce && !isLinear) && <>{playlistIsFinished ? "done" : "on track"}</>}
                {(isLinear && !isAllAtOnce) && <>{toBeDone}</>}
            </T.PoiProgressContainer>
            {showBtns &&
                <T.PoiActionsContainer>
                    <CancelWithConfirmation
                        cancel={delIt(false)}
                        iconColor="@darkGrey"
                        kind="transparentButton"
                        icon="undo"
                        cancelText={t("cancel")}
                        confirmText={t("yes, reset state")}
                        tooltipInitialButton={t("reset state")}
                        iconOnly
                        floated='right'
                    />
                    <CancelWithConfirmation
                        cancel={delIt(true)}
                        iconColor="@darkGrey"
                        kind="transparentButton"
                        icon="resume"
                        cancelText={t("cancel")}
                        confirmText={t("yes, reset state with events")}
                        tooltipInitialButton={t("reset state with events")}
                        iconOnly
                        floated='right'
                    />
                </T.PoiActionsContainer>
            }
            <div />
        </>
    );
}
export default PlaylistOverviewItem;
