import { ControllerHelper, PlayListNodeDownloadDto, PlaylistController, PlaylistStateDownloadDto, PlaylistUploadDto } from 'collaboration-service';
import { Button, Checkbox, Icon, Input, Loader, aboveBreakpoint, aboveBreakpointOrEqual, belowBreakpointOrEqual, useThemePart } from 'imaginarity-react-ui';
import _ from 'lodash';
import Carousel from 'nuka-carousel';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState, Ordering } from 'services/ApplicationState/ApplicationState';
import { getHighestAbsDueDate, shallowCompare } from 'services/Helpers';
import { gotoNamedRouteMethod } from 'services/Helpers/RoutingHelper';
import { getTranslated } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { isAdmin } from 'services/StoreDependantHelpers';
import { ThemeContext } from 'styled-components';
import { getSlideProperties } from 'views/MyHome/myHomeHelpers';
import PlaylistOverviewCarouselItem from './PlaylistOverviewCarouselItem';
import PlaylistOverviewItem from './PlaylistOverviewItem';
import { PlaylistSC as T } from './PlaylistSC';
import { useDispatch } from 'react-redux';
import { Actions } from 'services/ApplicationState/Actions';

const mapper = (state: ApplicationState) => ({
    ordering: state.playlistsState.ordering,
    playlistStatesTopic: state.playlistsState.playlistStatesTopic,
    contentLanguage: state.contentLanguage,
});

interface PlaylistOverviewProps {
}

const PlaylistOverview = (p: PlaylistOverviewProps) => {
    const { ordering, playlistStatesTopic, contentLanguage } = useSelector(mapper, shallowCompare);

    const dispatch = useDispatch();
    const { t } = useImgI18N("playlist");
    const contentWidth = useThemePart(x => x.content.width);
    const { slidesToShow, slidesGap, slideWidth } = getSlideProperties(contentWidth);
    const [showBtns, setShowBtns] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [list, setList] = React.useState<PlaylistStateDownloadDto[]>(playlistStatesTopic ?? []);
    const [listDone, setListDone] = React.useState<PlaylistStateDownloadDto[]>(playlistStatesTopic ?? []);
    const [sortBy, setSortBy] = React.useState("");
    const [reverseSort, setReverseSort] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState<string>("");

    const listLinear = _.filter(list, f => (f.playlist as PlaylistUploadDto).sequencingMode === "Linear");
    const hasMandatoryEntries = (list && list.length > 0);
    const hasOptionalEntries = false;
    const listAllFilteredByNotDone = _.filter(list, l => l.state !== "Finished");

    React.useEffect(() => {
        const loadPlaylistStates = async () => {
            const states = await ControllerHelper.singleCall({}, PlaylistController.GetPlaylistStates, true);
            dispatch(Actions.setPlaylistStatesTopic(states?.elements ?? []));
        };

        if (playlistStatesTopic !== undefined) {
            setLoading(false);
            let sorted: PlaylistStateDownloadDto[] = playlistStatesTopic;

            if (ordering === Ordering.date) {
                sorted = _.sortBy(playlistStatesTopic, p => getHighestAbsDueDate(p));
            } else if (ordering === Ordering.alpha) {
                sorted = _.sortBy(playlistStatesTopic, p => getTranslated(p.playlist.headlines, contentLanguage).text);
            }

            const filteredNotDone = sorted
                .filter(p => p.state !== "Finished")
                .filter(p =>
                    p.playlist.headlines.some(headline =>
                        headline.text.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                );

            const filteredDone = sorted
                .filter(p => p.state === "Finished")
                .filter(p =>
                    p.playlist.headlines.some(headline =>
                        headline.text.toLowerCase().includes(searchTerm.toLowerCase())
                    )
                );

            setList(filteredNotDone);
            setListDone(filteredDone);
        } else {
            loadPlaylistStates();
        }
    }, [playlistStatesTopic, ordering, contentLanguage, searchTerm, dispatch]);




    const isOverdue = (node: PlayListNodeDownloadDto) => {
        if (node && node.dueDateRelativeTo !== undefined) {
            if (node.dueDateRelativeTo === "Absolute" && node.absDueDate && new Date(node.absDueDate) < new Date()) {
                return true;
            }

            if (
                node.dueDateRelativeTo !== "Absolute" &&
                node.dueDateRelativeInHours &&
                new Date(new Date().getTime() + node.dueDateRelativeInHours * 60 * 60 * 1000) < new Date()
            ) {
                return true;
            }
        }
        return false;
    };

    const getProgressStatus = React.useCallback((node: PlayListNodeDownloadDto, p: PlaylistStateDownloadDto) => {
        if (node && node.dueDateRelativeTo !== undefined) {
            if (p.state === "Finished") {
                return "done";
            }

            if (isOverdue(node)) {
                return "overdue";
            }

            if (node.dueDateRelativeTo === "Absolute") {
                const now = new Date();
                const dueDate = new Date(node.absDueDate || 0);

                if (dueDate > now && dueDate < new Date(now.getTime() + 48 * 60 * 60 * 1000)) {
                    return "warning";
                } else if (dueDate > now) {
                    return "on Track";
                }
            }

            if (
                node.dueDateRelativeTo &&
                node.dueDateRelativeTo !== "Absolute" &&
                node.dueDateRelativeInHours
            ) {
                const now = new Date();
                const dueDate = new Date(now.getTime() + node.dueDateRelativeInHours * 60 * 60 * 1000);

                if (dueDate > now && dueDate < new Date(now.getTime() + 48 * 60 * 60 * 1000)) {
                    return "warning";
                } else if (dueDate > now) {
                    return "on Track";
                }
            }
        }

        return "";
    }, []);

    const extractDuration = (playlistState: PlaylistStateDownloadDto) => {
        const durations = playlistState.curNodes
            .concat(playlistState.prevNodes)
            .concat(playlistState.nextNodes)
            .map(node => node.durationInMinutes || 0);

        return durations.reduce((total, duration) => total + duration, 0);
    };

    const sortList = React.useCallback((sortKey: string) => {
        if (playlistStatesTopic) {
            let sortedNotDone: PlaylistStateDownloadDto[] = [...list]; // Nicht-Finished-Elemente
            let sortedDone: PlaylistStateDownloadDto[] = [...listDone]; // Finished-Elemente

            setSortBy(sortKey);
            setReverseSort(prevReverseSort => !prevReverseSort);

            switch (sortKey) {
                case "name":
                    sortedNotDone = _.sortBy(list, p => getTranslated(p.playlist.headlines, contentLanguage).text);
                    sortedDone = _.sortBy(listDone, p => getTranslated(p.playlist.headlines, contentLanguage).text);
                    break;
                case "duedate":
                    sortedNotDone = _.sortBy(list, p => getHighestAbsDueDate(p));
                    sortedDone = _.sortBy(listDone, p => getHighestAbsDueDate(p));
                    break;
                case "duration":
                    sortedNotDone = _.sortBy(sortedNotDone, p => extractDuration(p));
                    sortedDone = _.sortBy(sortedDone, p => extractDuration(p));
                    break;
                case "progress":
                    sortedNotDone = _.sortBy(sortedNotDone, p => {
                        const curNode = p.curNodes[0];
                        const progressStatus = getProgressStatus(curNode, p);

                        switch (progressStatus) {
                            case "done":
                                return 0;
                            case "overdue":
                                return 1;
                            case "on Track":
                                return 2;
                            case "warning":
                                return 3;
                            default:
                                return 4;
                        }
                    });
                    sortedDone = _.sortBy(sortedDone, p => {
                        const curNode = p.curNodes[0];
                        const progressStatus = getProgressStatus(curNode, p);

                        switch (progressStatus) {
                            case "done":
                                return 0;
                            case "overdue":
                                return 1;
                            case "on Track":
                                return 2;
                            case "warning":
                                return 3;
                            default:
                                return 4;
                        }
                    });
                    break;
                default:
                    break;
            }

            if (reverseSort) {
                sortedNotDone.reverse();
                sortedDone.reverse();
            }

            setList(sortedNotDone);
            setListDone(sortedDone);
        }
    }, [list, listDone, reverseSort, playlistStatesTopic, getProgressStatus, contentLanguage]);


    const theme = React.useContext(ThemeContext);
    const isMobile = belowBreakpointOrEqual({ theme }, "largeMobile");
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const isLaptop = aboveBreakpointOrEqual({ theme }, "tablet");
    const isDesktop = aboveBreakpoint({ theme }, "desktop");
    const admin = isAdmin("WIKI");
    const adminAndNotMobile = admin && !isMobile;



    return (
        <div style={{ padding: isMobile ? 10 : 0, paddingBottom: 50 }}>
            {loading ? <Loader active infoText={t("loading playlists")} size='small' light /> :
                <>
                    {hasMandatoryEntries &&
                        <>
                            <T.Headline>
                                {searchTerm === "" ? t("my playlists") : t("my filtered playlists")}
                            </T.Headline>
                            <T.CarouselContainer>
                                <Carousel
                                    slidesToShow={slidesToShow}
                                    dragging={listLinear.length > slidesToShow}
                                    slideWidth={slideWidth}
                                    cellSpacing={slidesGap}
                                    slidesToScroll={slidesToShow}
                                    renderCenterRightControls={null}
                                    renderCenterLeftControls={null}
                                    renderBottomCenterControls={null}
                                    renderTopRightControls={slidesToShow >= listAllFilteredByNotDone.length ? null : ({ previousSlide, nextSlide, currentSlide, slideCount }) => (listLinear.length > slidesToShow &&
                                        <>
                                            {contentWidth >= 480 &&
                                                <T.CarouselButtonLeft onClick={previousSlide} disabled={currentSlide === 0}>
                                                    <Icon name="chevron left" size="22px" />
                                                </T.CarouselButtonLeft>
                                            }
                                            {contentWidth >= 480 &&
                                                <T.CarouselButtonRight onClick={nextSlide} disabled={slideCount === currentSlide + slidesToShow}>
                                                    <Icon name="chevron right" size="22px" />
                                                </T.CarouselButtonRight>
                                            }

                                        </>
                                    )}
                                >
                                    {_.map(_.filter(list, l => l.state !== "Finished"), (p, i) => {
                                        const isAAO = p.curNodes.length === 0;
                                        return (
                                            <PlaylistOverviewCarouselItem data={p} isAAO={isAAO} key={i} />
                                        );
                                    })}
                                </Carousel>
                            </T.CarouselContainer>
                        </>
                    }
                    <T.Headline>
                        {searchTerm === "" ? t("all playlists") : t('all playlists filtered by "{{search}}"', { search: searchTerm })}
                        <T.HeadlineInput>
                            <div style={{ marginRight: adminAndNotMobile ? 40 : 0 }}>
                                <Input width={250} value={searchTerm} autoComplete='off' icon='search' onChange={e => setSearchTerm(e.target.value)} style={{ fontSize: "1rem" }} type='search' kind='default' />
                            </div>
                            {searchTerm !== "" &&
                                <T.HeadlineInputClear onClick={() => setSearchTerm("")} style={{ right: adminAndNotMobile ? 50 : 10 }}>
                                    <Icon name='times' color='@lightGrey' marginTop={0} />
                                </T.HeadlineInputClear>
                            }
                            {adminAndNotMobile &&
                                <div style={{ position: "absolute", top: 0, right: 0, zIndex: 5 }}>
                                    <Button
                                        icon="playlist edit"
                                        kind="cancelButton"
                                        onClick={gotoNamedRouteMethod("playlist_manage")}
                                        tooltip={{ tooltipText: t("playlist edit") }}
                                    />
                                </div>
                            }
                        </T.HeadlineInput>
                    </T.Headline>
                    <T.TableGrid showButtons={showBtns} isDesktop={isDesktop}>
                        <T.TitleRowCell />
                        <T.TitleRowCell style={{ padding: 0 }} />
                        <T.TitleRowCell onClick={() => sortList("name")}>
                            <div>{t("name")}</div>
                            {sortBy === "name" ?
                                <div><Icon name={reverseSort ? "arrow up" : "arrow down"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell>
                        {isDesktop ? <T.TitleRowCell onClick={() => sortList("assigned")}>
                            <div>{t("assigned")}</div>
                            {sortBy === "assigned" ?
                                <div><Icon name={reverseSort ? "arrow up" : "arrow down"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        {isTablet ? <T.TitleRowCell onClick={() => sortList("duedate")}>
                            <div>{t("due date")}</div>
                            {sortBy === "duedate" ?
                                <div><Icon name={reverseSort ? "arrow up" : "arrow down"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        {isLaptop ? <T.TitleRowCell onClick={() => sortList("duration")}>
                            <div>{t("duration")}</div>
                            {sortBy === "duration" ?
                                <div><Icon name={reverseSort ? "arrow up" : "arrow down"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell> : <div />}
                        <T.TitleRowCell onClick={() => sortList("progress")}>
                            <div>{t("progress")}</div>
                            {sortBy === "progress" ?
                                <div><Icon name={reverseSort ? "arrow up" : "arrow down"} style={{ float: "left", marginLeft: 5, }} size="14px" marginTop={3} /></div>
                                : <div />}
                        </T.TitleRowCell>
                        {showBtns && <T.TitleRowCell>actions</T.TitleRowCell>}
                        <T.TitleRowCell />

                        <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 10 : 9 }} error={!hasMandatoryEntries}>
                            {hasMandatoryEntries ? t("mandatory") : t("no mandatory playlists found")}
                        </T.HeadlineRow>


                        {hasMandatoryEntries && _.map(list, (p, i) => {
                            const isAAO = p.curNodes.length === 0;
                            return (
                                <div className="rowWrapper" key={i}>
                                    <PlaylistOverviewItem data={p} isAAO={isAAO} key={p.id} showBtns={showBtns} />
                                </div>
                            );
                        }
                        )}

                        {hasOptionalEntries &&
                            <>

                                <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 10 : 9, marginTop: hasMandatoryEntries ? 17 : 0 }}>
                                    {/* {hasAaoEntries ? t("optional") : t("no optional playlists found")} */}
                                    optional (this function is not yet available)
                                </T.HeadlineRow>

                                {/* {hasAaoEntries && _.map(listAAO, (p, i) => {
                                    const isAAO = p.curNodes.length === 0;
                                    return (
                                        <PlaylistOverviewItem data={p} isAAO={isAAO} key={i} showBtns={showBtns} />
                                    );
                                })} */}
                            </>
                        }
                        {listDone.length > 0 &&
                            <>
                                <T.HeadlineRow style={{ gridColumnEnd: showBtns ? 10 : 9 }} error={!hasMandatoryEntries}>
                                    {t("my completed playlists")}
                                </T.HeadlineRow>

                                {_.map(listDone, (p, i) => {
                                    const isAAO = p.curNodes.length === 0;
                                    return (
                                        <div className="rowWrapper" key={i}>
                                            <PlaylistOverviewItem data={p} isAAO={isAAO} key={p.id} showBtns={showBtns} />
                                        </div>
                                    );
                                }
                                )}
                            </>
                        }
                    </T.TableGrid>

                </>
            }
            {admin &&
                <T.AdminPanel >
                    <div style={{ float: "left", lineHeight: "40px", paddingLeft: 20 }}>Temporary Admin Panel</div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 9, cursor: "pointer" }} onClick={() => setShowBtns(!showBtns)}>
                        Reset  Buttons
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='hide' onClick={() => !showBtns ? undefined : setShowBtns(false)} selected={!showBtns} />
                    </div>
                    <div style={{ float: "right", lineHeight: "20px", marginRight: 10, marginTop: 7 }}>
                        <Checkbox label='show' onClick={() => showBtns ? undefined : setShowBtns(true)} selected={showBtns} />
                    </div>
                    <div style={{ float: "right", borderLeft: "1px solid #e9e9e9", width: 1, height: "100%", marginRight: 10 }} />
                </T.AdminPanel>
            }

        </div>
    );
}
export default PlaylistOverview;