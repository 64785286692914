import { PlayListNodeDownloadDto, PlaylistStateDownloadDto } from 'collaboration-service';
import SafeHTML from 'components/SafeHTML/SafeHTML';
import { differenceInDays, differenceInHours } from 'date-fns';
import { Icon, ImgIcons, Tooltip, aboveBreakpoint } from 'imaginarity-react-ui';
import _ from 'lodash';
import * as React from 'react';
import { ApplicationState, useAppSelector } from 'services/ApplicationState/ApplicationState';
import { addHoursToISODate, calcMinsInHoursAndMins, shallowCompare } from 'services/Helpers';
import { getTranslated, getTranslatedStandardDate } from 'services/Helpers/TranslationHelpers';
import { useImgI18N } from 'services/ImgI18N';
import { useAppTheme } from 'services/useAppTheme';
import { ThemeContext } from 'styled-components';
import { NodeState } from './PlaylistDetail';
import { PlaylistDetailSC as T } from './PlaylistDetailSC';
import { PlaylistTheme } from './PlaylistMain';

const mapper = (state: ApplicationState) => ({ contentLanguage: state.contentLanguage });

interface PlaylistTimeLineProps {
    data: PlaylistStateDownloadDto;
    hasWarning?: (w: boolean) => void;
}

const PlaylistTimeLine = (p: PlaylistTimeLineProps) => {
    const { data, hasWarning } = p;
    const { contentLanguage } = useAppSelector(mapper, shallowCompare);
    const [hoveredDotId, setHoveredDotId] = React.useState<string | null>(null);
    const { t } = useImgI18N("playlist");
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const prevXPositionRef = React.useRef<number>(0);
    const prePrevXPositionRef = React.useRef<number>(0);
    const containerWidth = containerRef.current ? containerRef.current.getBoundingClientRect().width : 0;
    const allNodes = [...data.prevNodes, ...data.curNodes, ...data.nextNodes];
    const theme = React.useContext(ThemeContext);
    const isTablet = aboveBreakpoint({ theme }, "largeMobile");
    const currentDate = new Date();
    const playlistTheme = useAppTheme<PlaylistTheme>("playlistTheme");


    const todayEntry: PlayListNodeDownloadDto = {
        id: "todayId",
        headlines: [{ lng: 'en-GB', text: 'today' }],
        descriptions: [],
        color: '@darkGrey',
        dueDateRelativeTo: "Absolute",
        absDueDate: new Date(),
    };
    const isNotFinished = data.state !== "Finished";
    if (isNotFinished)
        allNodes.unshift(todayEntry);


    const sortedData = _.sortBy(allNodes, (node) => {
        if (node.dueDateRelativeTo === "Absolute" && node.absDueDate) {
            return new Date(node.absDueDate);
        } else if (node.dueDateRelativeTo !== "Absolute" && node.dueDateRelativeInHours) {
            return addHoursToISODate(data.assignmentDate, node.dueDateRelativeInHours);
        } else {
            return new Date();
        }
    });

    function convertToValidDate(node: PlayListNodeDownloadDto): Date | undefined {
        if (node.dueDateRelativeTo === "Absolute" && node.absDueDate) {
            const dateValue = new Date(node.absDueDate);
            if (!isNaN(dateValue.getTime())) {
                return dateValue;
            }
        } else if (
            node.dueDateRelativeTo !== undefined &&
            node.dueDateRelativeTo !== "Absolute" &&
            node.dueDateRelativeInHours
        ) {
            const dateValue = new Date(
                addHoursToISODate(data.assignmentDate, node.dueDateRelativeInHours)
            );
            if (!isNaN(dateValue.getTime())) {
                return dateValue;
            }
        }
        return undefined;
    }

    const validDates = sortedData.map(convertToValidDate).filter(date => date !== undefined) as Date[];

    const earliestDate = new Date(Math.min(...validDates.map(date => date.getTime())));
    const latestDate = isNotFinished ? new Date(Math.max(...validDates.map(date => date.getTime()))) : validDates[validDates.length - 1];
    const isTodayInRange = earliestDate && latestDate && currentDate > earliestDate && currentDate < latestDate;

    const groupedData = _.groupBy(sortedData, (node) => {
        if (node.dueDateRelativeTo === "Absolute" && node.absDueDate) {
            return node.absDueDate;
        }
        else if (node.dueDateRelativeTo !== "Absolute" && node.dueDateRelativeInHours && node.dueDateRelativeInHours > 0) {
            const calculatedDate = addHoursToISODate(data.assignmentDate, node.dueDateRelativeInHours);

            if (typeof calculatedDate === 'object' && calculatedDate !== null) {
                return (calculatedDate as Date).toISOString();
            } else if (typeof calculatedDate === 'string') {
                return calculatedDate;
            } else {
                return null;
            }
        }
        else {
            return new Date().toISOString();
        }
    });

    const lineWidth = data.state === "Finished" ? 100 : (((currentDate.getTime() - earliestDate.getTime())) / (latestDate.getTime() - earliestDate.getTime())) * 100;
    // const shouldToday = !(isTodayInRange && earliestDate && latestDate);
    const marginTopStep = playlistTheme.marginTopStep;
    const limitDistance = playlistTheme.limitDistance;
    const warningLess_X_Hours = playlistTheme.warningLess_X_Hours;

    const getNodeState = React.useMemo(() => (n: PlayListNodeDownloadDto, dif: number): NodeState => {
        let ret = {
            state: 'Overdue',
            finished: 0
        } as NodeState;

        if (data) {
            _.forEach(n.referenceIds, refId => {
                const state = _.find(data.itemStates, st => st.referenceId === refId) ?? _.find(data.prevItemStates, st => st.referenceId === refId);
                if (state?.state === 'Finished')
                    ret.finished++;
            });

            const first = _.first(n.children);
            const transFunction = first?.transitionFunction;
            const transValue = first?.referenceValue ?? 0;
            const refId = first?.referenceContentId ?? '';

            if (transFunction === "FallThru") {
                ret.state = "Finished";
            }
            if (transFunction === "AbsCountDone") {
                const f = _.filter(data.prevItemStates, st => st.state === "Finished");
                if (f.length >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "PercentageDone") {
                const f = _.filter(data.itemStates, st => st.state === "Finished");
                const doneNow = f.length / data.itemStates.length;
                if (doneNow >= transValue)
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDone") {
                const s = _.find(data.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (transFunction === "ReferenceDoneWithValue") {
                const s = _.find(data.prevItemStates, st => st.referenceId === refId);
                if (s && s.state === 'Finished')
                    ret.state = "Finished";
            }
            if (n.referenceIds?.length === ret.finished)
                ret.state = 'Finished';
            else {
                if (dif >= 0 && ret.state !== 'Finished')
                    ret.state = 'On Track';
            }
        }
        return ret;
    }, [data]);

    let lastGroupEndXPosition = 0;
    let lastGroupMarginTop = 0;
    let phaseIdx = 0;

    const groupEntryCounts = Object.values(groupedData).reduce((acc, group) => { return acc + group.length; }, 0) + (isTodayInRange ? 0 : 1);
    const reqMinWidth = groupEntryCounts * limitDistance / 2;
    const enoughSpace = containerWidth - reqMinWidth > 0;
    const maxElementsperDay = Math.max(...Object.values(groupedData).map(array => array.length));

    return (
        <T.Container ref={containerRef} items={maxElementsperDay * marginTopStep * 2}>
            {enoughSpace ?
                <>
                    <T.TimeLineAll />
                    <T.ContainerInner>
                        {/* {shouldToday && isNotFinished &&
                            <>
                                <T.Dot left={lineWidth} hovered={false} color="@darkGrey" />
                                <T.DueDate
                                    left={lineWidth}
                                    hovered={false}
                                    idx={10}
                                    color='@darkerGrey'
                                >
                                    {t("today")}
                                </T.DueDate>
                            </>
                        } */}
                        <T.Dot left={lineWidth} hovered={false} color="@lightGrey" />
                        <T.TimeTodayLine left={lineWidth} />
                        <T.DueDate
                            left={lineWidth}
                            hovered={false}
                            idx={10}
                            color='@lightGrey'
                            style={{ top: "calc(50% + 52px)" }}
                        >
                            {t("today")}
                        </T.DueDate>



                        {_.map(Object.entries(groupedData), ([groupKey, groupItems], gId) => {
                            let marginTop = 0;
                            phaseIdx++;
                            return (
                                <React.Fragment key={gId}>
                                    {_.map(groupItems, (n, i) => {
                                        if (n.id === "todayId")
                                            return null;
                                        let dateValue;

                                        if (n.dueDateRelativeTo === 'Absolute' && n.absDueDate) {
                                            dateValue = new Date(n.absDueDate);
                                        } else if (n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) {
                                            dateValue = new Date(addHoursToISODate(data.assignmentDate, n.dueDateRelativeInHours));
                                        }


                                        if (dateValue) {
                                            const left = (((dateValue.getTime() - earliestDate.getTime())) / (latestDate.getTime() - earliestDate.getTime())) * 100;
                                            const xPosition = Math.round(containerWidth * left / 100);
                                            const prevXPosition = prevXPositionRef.current === Math.round(containerWidth) ? 0 : prevXPositionRef.current;
                                            const prePrevXPosition = prevXPositionRef.current === Math.round(containerWidth) ? 0 : prePrevXPositionRef.current;

                                            if (groupItems.length > 1) {
                                                lastGroupEndXPosition = xPosition;
                                                lastGroupMarginTop = marginTop;
                                            } else {
                                                if (xPosition - lastGroupEndXPosition <= limitDistance) {
                                                    marginTop += (lastGroupMarginTop + ((groupItems.length + 1) * marginTopStep) + 2);
                                                }
                                            }

                                            if (phaseIdx === 1)
                                                marginTop = 0;

                                            prevXPositionRef.current = xPosition;
                                            prePrevXPositionRef.current = prevXPosition;

                                            const dueTo = new Date(dateValue);
                                            const isDotHovered = hoveredDotId === n.id;
                                            const getYear = getTranslatedStandardDate(dateValue, 'yy');
                                            const getActYear = getTranslatedStandardDate(currentDate, 'yy');
                                            const formatedDate = getTranslatedStandardDate(dateValue, getYear === getActYear ? 'dd. MMM' : 'dd. MMM y');
                                            const dif = differenceInDays(new Date(dateValue as Date), currentDate);
                                            const nodeState = getNodeState(n, dif);
                                            const stateOfNode = nodeState.state;
                                            const nodeIsInFuture = currentDate < dateValue;
                                            const diffInHours = differenceInHours(new Date(dateValue as Date), currentDate);
                                            const warning = diffInHours > 0 && diffInHours < warningLess_X_Hours;
                                            const isToday = dateValue.getDate() === currentDate.getDate() &&
                                                dateValue.getMonth() === currentDate.getMonth() &&
                                                dateValue.getFullYear() === currentDate.getFullYear();


                                            const dotColor = (data.state === "Finished" || (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === n.id)) || nodeState.finished === 1) ? "@accentGreen" : warning ? "@accentOrange" : isToday ? "@accentOrange" : nodeIsInFuture ? "@neutralLow" : stateOfNode === "On Track" ? "@accentGreen" : stateOfNode === "Overdue" ? "@accentRed" : "@darkGrey";
                                            const fontColor = (data.state === "Finished" || (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === n.id)) || nodeState.finished === 1) ? "@accentGreen" : warning ? "@accentOrange" : isToday ? "@accentOrange" : nodeIsInFuture ? "@darkGrey" : stateOfNode === "On Track" ? "@accentGreen" : stateOfNode === "Overdue" ? "@accentRed" : "@darkGrey";
                                            const lineColor = (data.state === "Finished" || (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === n.id)) || nodeState.finished === 1) ? "@accentGreen" : warning ? "@accentOrange" : isToday ? "@accentOrange" : stateOfNode === "On Track" ? "@accentGreen" : stateOfNode === "Overdue" ? "@accentRed" : "@darkGrey"
                                            const actBarWidth = (lineWidth - left);

                                            if (hasWarning && warning === true) { hasWarning(true); }

                                            const catMTfirst = -marginTop - 7;
                                            const distanceToPrevTooSmall = xPosition - prevXPosition < limitDistance;
                                            const distanceToPrePrevOK = prevXPosition - prePrevXPosition > limitDistance;
                                            const addMoreMargin = (distanceToPrevTooSmall && distanceToPrePrevOK) ? marginTopStep : 0
                                            const totalMarginReduction = marginTop + marginTopStep;
                                            const baseAdjustment = 8 - addMoreMargin;
                                            const conditionalAdjustment = groupItems.length > 1 ? 5 : 0;
                                            const catMTrest = -groupItems.length * totalMarginReduction + baseAdjustment + conditionalAdjustment;
                                            const catMT = phaseIdx === 1 ? catMTfirst : catMTrest;
                                            const dateMT = (distanceToPrevTooSmall && distanceToPrePrevOK) ? marginTopStep : marginTop;

                                            return (
                                                <div
                                                    key={n.id}
                                                    onMouseEnter={() => n.id && setHoveredDotId(n.id)}
                                                    onMouseLeave={() => setHoveredDotId(null)}
                                                >
                                                    {i === 0 && (
                                                        <>
                                                            <T.Dot
                                                                left={left}
                                                                hovered={isDotHovered}
                                                                color={dotColor}
                                                            />
                                                            {warning && data.state !== "Finished" &&
                                                                <T.TimeLineAllProgress
                                                                    width={left}
                                                                    left={left}
                                                                    color={"@warning"}
                                                                    style={{ marginTop: 0, zIndex: 1, transform: "translateX(-100%)" }}
                                                                />
                                                            }
                                                            <T.TimeLineAllProgress
                                                                width={actBarWidth}
                                                                left={left}
                                                                color={lineColor}
                                                                style={{ marginTop: 0, zIndex: 2 }}
                                                            />
                                                            {isTablet &&
                                                                <T.Category
                                                                    left={left}
                                                                    hovered={isDotHovered}
                                                                    idx={(i + 1) + 100}
                                                                    style={{
                                                                        marginTop: gId === 1 ? (-marginTopStep / 2) : catMT,
                                                                        padding: groupItems.length > 1 ? "5px 0" : "5px 0 0 0"
                                                                    }}
                                                                    color={fontColor}
                                                                >
                                                                    {_.map(groupItems, (g, k) => {
                                                                        return (
                                                                            <T.CategoryItem key={k} >
                                                                                <Tooltip
                                                                                    tooltipText=''
                                                                                    cursor='help'
                                                                                    tooltipContent={<div style={{ textAlign: "left" }}>
                                                                                        <div style={{ fontWeight: "bolder" }}>
                                                                                            <SafeHTML
                                                                                                allowedTags={[]}
                                                                                                allowedAttributes={{}}
                                                                                                html={getTranslated(g.headlines, contentLanguage)?.text}
                                                                                            />
                                                                                        </div>
                                                                                        <T.ToolTipDiv>
                                                                                            {g.descriptions !== undefined &&
                                                                                                <SafeHTML
                                                                                                    html={getTranslated(g.descriptions, contentLanguage)?.text ?? ""}
                                                                                                />
                                                                                            }
                                                                                        </T.ToolTipDiv>
                                                                                        <T.ToolTipFooter>
                                                                                            <div>
                                                                                                <Icon name="calendar" style={{ float: "left", marginRight: 5 }} marginTop={-2} size={16} />
                                                                                                <div style={{ float: "left" }}>
                                                                                                    {getTranslatedStandardDate(dueTo, "P")}
                                                                                                </div>
                                                                                                {(n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) &&
                                                                                                    <>
                                                                                                        <Icon name="clock" style={{ float: "left", marginLeft: 10, marginRight: 5 }} marginTop={-2} size={16} />
                                                                                                        <div style={{ float: "left" }}>
                                                                                                            {getTranslatedStandardDate(dueTo, "p")}
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                            {n.durationInMinutes &&
                                                                                                <div>
                                                                                                    <Icon name="clock" style={{ float: "left", marginRight: 5 }} marginTop={-1} size={16} />
                                                                                                    {calcMinsInHoursAndMins(n.durationInMinutes, t)}
                                                                                                </div>
                                                                                            }
                                                                                        </T.ToolTipFooter>

                                                                                    </div>}
                                                                                    noMargin
                                                                                >
                                                                                    <SafeHTML
                                                                                        allowedTags={[]}
                                                                                        allowedAttributes={{}}
                                                                                        html={getTranslated(g.headlines, contentLanguage)?.text}
                                                                                    />
                                                                                </Tooltip>
                                                                            </T.CategoryItem>
                                                                        );
                                                                    }
                                                                    )}

                                                                </T.Category>
                                                            }
                                                            <T.DueDate
                                                                left={left}
                                                                hovered={isDotHovered}
                                                                idx={(i + 1) + 100}
                                                                style={{ marginTop: gId === 1 ? 0 : dateMT }}
                                                                color={fontColor}
                                                            >
                                                                {(n.dueDateRelativeTo === 'Absolute' && n.absDueDate) && formatedDate}
                                                                {(n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) && formatedDate}
                                                            </T.DueDate>
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </React.Fragment>
                            )
                        })}
                    </T.ContainerInner>
                </>
                :
                <>
                    <T.TLsmallDisplayGrid>
                        {_.map(Object.entries(groupedData), ([groupKey, groupItems], gId) => {
                            return (
                                <React.Fragment key={gId}>
                                    {_.map(groupItems, (n, i) => {
                                        const isTodayEntry = n.id === "todayId";
                                        let dateValue;

                                        if (n.dueDateRelativeTo === 'Absolute' && n.absDueDate) {
                                            dateValue = new Date(n.absDueDate);
                                        } else if (n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) {
                                            dateValue = new Date(addHoursToISODate(data.assignmentDate, n.dueDateRelativeInHours));
                                        }
                                        if (dateValue) {
                                            const dif = differenceInDays(new Date(dateValue as Date), currentDate);
                                            const nodeState = getNodeState(n, dif);
                                            const stateOfNode = nodeState.state;
                                            const diffInHours = differenceInHours(new Date(dateValue as Date), currentDate);
                                            const warning = diffInHours > 0 && diffInHours < warningLess_X_Hours;
                                            const isToday = dateValue.getDate() === currentDate.getDate() &&
                                                dateValue.getMonth() === currentDate.getMonth() &&
                                                dateValue.getFullYear() === currentDate.getFullYear();

                                            const dotColor = (data.state === "Finished" || (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === n.id))) ? "@accentGreen" : isToday ? "@accentOrange" : warning ? "@accentOrange" : stateOfNode === "On Track" ? "@accentGreen" : stateOfNode === "Overdue" ? "@accentRed" : "@darkGrey";
                                            const iconType: ImgIcons = (data.state === "Finished" || (stateOfNode === "Finished" && _.find(data.prevNodes, pv => pv.id === n.id))) ? "check circle" : warning ? "exclamation triangle" : stateOfNode === "On Track" ? "clock" : stateOfNode === "Overdue" ? "exclamation triangle" : "clock";
                                            const dueTo = new Date(dateValue);
                                            return (
                                                <T.TLsmallDisplayRow key={i} color={dotColor} isTodayEntry={isTodayEntry}>
                                                    <T.TLsmallDisplayRowIndicatorContainer>
                                                        <T.TLsmallDisplayRowLine width={(gId) * 10 + 10} />
                                                        <T.TLsmallDisplayRowDot color={isTodayEntry ? "@neutralLow" : dotColor} left={(gId) * 10 + 10} />
                                                    </T.TLsmallDisplayRowIndicatorContainer>
                                                    <T.TLsmallDisplayGridItem style={{ paddingLeft: (gId + 1) * 10 + marginTopStep }}>
                                                        {getTranslatedStandardDate(dueTo, "PP")}
                                                        {(n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) &&
                                                            <span style={{ paddingLeft: 10 }}>
                                                                {getTranslatedStandardDate(dueTo, "p")}
                                                            </span>
                                                        }
                                                    </T.TLsmallDisplayGridItem>
                                                    <T.TLsmallDisplayGridItem>
                                                        <SafeHTML
                                                            html={getTranslated(n.headlines, contentLanguage)?.text ?? ""}
                                                            allowedTags={[]}
                                                            allowedAttributes={{}}
                                                        />
                                                    </T.TLsmallDisplayGridItem>
                                                    <T.TLsmallDisplayGridItemInfo style={{ height: 24 }}>
                                                        {n.descriptions && n.descriptions.length > 0 &&
                                                            <Tooltip
                                                                tooltipText=''
                                                                notInline
                                                                cursor='default'
                                                                tooltipContent={<div style={{ textAlign: "left" }}>
                                                                    <div style={{ fontWeight: "bolder" }}>
                                                                        <SafeHTML
                                                                            allowedTags={[]}
                                                                            allowedAttributes={{}}
                                                                            html={getTranslated(n.headlines, contentLanguage)?.text}
                                                                        />
                                                                    </div>
                                                                    <T.ToolTipDiv>
                                                                        {n.descriptions !== undefined &&
                                                                            <SafeHTML
                                                                                html={getTranslated(n.descriptions, contentLanguage)?.text ?? ""}
                                                                            />
                                                                        }
                                                                    </T.ToolTipDiv>
                                                                    <T.ToolTipFooter>
                                                                        <div>
                                                                            <Icon name="calendar" style={{ float: "left", marginRight: 5 }} marginTop={-2} size={16} />
                                                                            <div style={{ float: "left" }}>
                                                                                {getTranslatedStandardDate(dueTo, "P")}
                                                                            </div>
                                                                            {(n.dueDateRelativeTo !== undefined && n.dueDateRelativeTo !== 'Absolute' && n.dueDateRelativeInHours) &&
                                                                                <>
                                                                                    <Icon name="clock" style={{ float: "left", marginLeft: 10, marginRight: 5 }} marginTop={-2} size={16} />
                                                                                    <div style={{ float: "left" }}>
                                                                                        {getTranslatedStandardDate(dueTo, "p")}
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                        {n.durationInMinutes &&
                                                                            <div>
                                                                                <Icon name="clock" style={{ float: "left", marginRight: 5 }} marginTop={-1} size={16} />
                                                                                {calcMinsInHoursAndMins(n.durationInMinutes, t)}
                                                                            </div>
                                                                        }
                                                                    </T.ToolTipFooter>

                                                                </div>}
                                                                noMargin
                                                                position="top"
                                                            >
                                                                <Icon name="info" color='@middleLightGrey' />
                                                            </Tooltip>
                                                        }
                                                    </T.TLsmallDisplayGridItemInfo>
                                                    <T.TLsmallDisplayGridItem>
                                                        {n.durationInMinutes && calcMinsInHoursAndMins(n.durationInMinutes, t)}
                                                    </T.TLsmallDisplayGridItem>
                                                    <T.TLsmallDisplayGridItem style={{ paddingRight: 10 }}>
                                                        {!isTodayEntry &&
                                                            <Icon name={iconType} color={dotColor} style={{ float: "right" }} marginTop={3} />
                                                        }
                                                    </T.TLsmallDisplayGridItem>

                                                </T.TLsmallDisplayRow>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </T.TLsmallDisplayGrid>
                </>
            }
        </T.Container>
    );
}
export default PlaylistTimeLine;